import React, { useRef, useEffect, useState, useContext } from "react";
import "./MyLandbot.scss";
import AppContext from 'contexts/AppContext';

// const core = new Core({
//   firebase,
//   configUrl: "https://landbot.pro/v3/H-1305104-INGEF3GN1V30T8A6/index.json"
// });

function MyLandbot({ url }) {
  //Context
  const { setMessageToSend, messageToSend, setMessages, messages } = useContext(AppContext);

  const containerRef = useRef(null);
  const [_landbot, setLandbot] = useState();
   


  useEffect(() => {

    setLandbot(new window.Landbot.Container({
      container: containerRef.current,
      configUrl: url,
      branding: false,
      
    }));
    console.log(url, containerRef, _landbot);

   

      //  return () => _landbot.destroy();

  }, []);

  useEffect(() => {
    
    if(_landbot && _landbot.core){
       _landbot.core.events.on('load', function() {
      console.log('Load complete!');
      widgetLoaded();

    });

    _landbot.core.events.on('new_message', function(e) {
      //console.log('Message received',e, e.message, _landbot);
      setMessages(
        oldArray => [...oldArray, e]
      )

    });
    }
  }, [_landbot]);

  function widgetLoaded(){
    var containerDiv =  _landbot.container;
  }

  useEffect(() => {
    
    if(messageToSend === "restart"){
      console.log(_landbot);
      setMessages([]);
      _landbot.core.sendMessage({
        message: "Empezar de nuevo",
        payload: "restart",
        type: "button"

      })
    }

    if(messageToSend === "call"){
      _landbot.core.sendMessage({
        message: "Agendar con un experto",
        payload: "call",
        type: "button"

      })
    }

    if(messageToSend === "examples"){
      _landbot.core.sendMessage({
        message: "Ver ejemplos",
        payload: "examples",
        type: "button"

      })
    }
    setMessageToSend("");
  }, [messageToSend]);



  return (

       <div className="MyLandbot" ref={containerRef} >

       </div>

   );
}
export default MyLandbot;
