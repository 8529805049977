
import React, { useRef, useEffect, useContext, useState } from "react";
import AppContext from "contexts/AppContext";
import MyLandbot from "components/MyLandbot/MyLandbot";
import './App.scss';
import Logo from 'images/blue-full.svg'
import RestartIcon from 'images/restart.svg'
import TalkIcon from 'images/talk.svg'

function App() {
  const [currentPage, setCurrentPage] = useState("Home");
  const [messages, setMessages] = useState([]);
  const [messageToSend, setMessageToSend] = useState("");
  useEffect(() => {
    console.log("mensajes", messages)
  }, [messages]);

  function sendMessage(message){
    console.log("deberia mandar mensaje");
    setMessageToSend(message);
  }

  return (
    <AppContext.Provider value={{ 
        currentPage:currentPage, 
        setCurrentPage:setCurrentPage,
        messageToSend: messageToSend,
        setMessageToSend: setMessageToSend,
        messages: messages,
        setMessages: setMessages
      }}>
      <div className="App">
        <div className="Chatbot">
          <MyLandbot url="https://landbot.pro/v3/H-1330105-ITX051F4HMFX4TRN/index.json" />
        </div>
        <div className="Website">
          <div className={`Talk ${messages.length > 2 ? "" : "pristine"}`}>
             <button onClick={() => sendMessage("call")}> Agendemos<img src={TalkIcon} alt="Agendemos" width={30}/> </button> 
          </div>
          {messages.length > 2 && (
            <div className="Restart">
              <button onClick={() => sendMessage("restart")}> Empezar de nuevo<img src={RestartIcon} alt="Reiniciar" width={30}/> </button> 
            </div>
          )}
          <div className="Logo">
            <a  href="https://bebot.design" target="_blank">
              <img src={Logo} alt="BeBot." width={140}/>
            </a>
          </div>
          <div className="Content">
            <h1>Diseñamos bots con vida propia.</h1>
            <p>Hacemos que dejen de ser solo máquinas y logren conectarse con las personas.</p>
            <p>👈 Try me.</p>
            <br/> <br/>
            <button className="Button main" onClick={() => sendMessage("call")}> Agendar con un experto </button>    <br/>     
            
            <button className="Button" onClick={() => sendMessage("examples")}> Ver ejemplos </button>          <br/>
          </div>
        </div>
      </div>
    </AppContext.Provider>
  );
}

export default App;
